import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ImSpinner, ImHammer2 } from "react-icons/im";
import { FaSpinner } from "react-icons/fa";
import { RiSendPlaneFill } from "react-icons/ri";
import toast, { Toaster } from "react-hot-toast";
import { ExternalLink } from "lucide-react";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Debounce Utility
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

// Error Boundary Component
class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="text-center text-red-500 p-4">
          <h1>Something went wrong!</h1>
          <p>{this.state.error?.message || "Unknown error occurred"}</p>
        </div>
      );
    }
    return this.props.children;
  }
}
// PlayerSearch Component
const PlayerSearch = ({ playerName, setPlayerName, searchPlayer, loading, handleNavigate }) => (
  <div className="relative max-w-md mx-auto mb-6">
    <input
      type="text"
      className="w-full bg-gray-900 border border-gray-700 rounded-md px-4 py-2 text-white focus:outline-none focus:border-indigo-500 transition-colors duration-200"
      placeholder="Enter player name"
      value={playerName}
      minLength="1"
      maxLength="16"
      onChange={(e) => setPlayerName(e.target.value)}
//      onClick={() => handleNavigate(playerName)}
      onKeyPress={(e) => e.key === "Enter" && handleNavigate(playerName)}
//      onKeyPress={(e) => e.key === "Enter" && searchPlayer()}
      disabled={loading}
    />
    {playerName && !loading && (
      <button
        className="absolute right-10 top-1/2 transform -translate-y-1/2 text-gray-400 mr-2 hover:text-white"
        onClick={() => setPlayerName("")}
        aria-label="Clear search"
      >
        ×
      </button>
    )}
    <button
      className={`absolute right-2 top-1/2 transform -translate-y-1/2 bg-indigo-600 hover:bg-indigo-700 text-white p-2 rounded-md flex items-center justify-center ${
        loading ? "cursor-not-allowed opacity-50" : ""
      }`}
      onClick={() => handleNavigate(playerName)}
      disabled={loading}
    >
      {loading ? <FaSpinner className="animate-spin" /> : <RiSendPlaneFill />}
    </button>
  </div>
);

// PlayerInfo Component
const PlayerInfo = ({ playerData, processMarkdownSyntax }) => {
  const getBackgroundColor = (label) => {
    const colors = {
      admin: "bg-red-600",
      titan: "bg-red-700",
      ultra: "bg-yellow-600",
      emerald: "bg-green-600",
      legend: "bg-cyan-500",
      crew: "bg-lime-500",
      trainee: "bg-yellow-500",
      banned: "bg-red-500",
      muted: "bg-yellow-500",
      tester: "bg-green-500",
      mod: "bg-blue-500",
      media: "bg-teal-500",
      supervisor: "bg-pink-500",
      "game designer": "bg-orange-600",
      partner: "bg-orange-500",
    };
    return colors[label.toLowerCase()] || "bg-gray-400";
  };

  if (!playerData) return null;

  return (
    <div className="p-6">
      <div className="flex flex-col items-center mb-6">
        <img
          src={playerData.avatar}
          alt={`${playerData.name}'s avatar`}
          className="rounded-full mb-4 border-2 border-indigo-500 w-28 h-28"
        />
        <h1 className="text-4xl font-bold mb-2">{playerData.name}</h1>
        <div className="flex flex-wrap justify-center mb-4">
          {playerData.ranks &&
            (Array.isArray(playerData.ranks) ? playerData.ranks : playerData.ranks.split(",")).map((rank, index) => (
              <span
                key={index}
                className={`text-white px-2 font-bold text-xs rounded-full m-1 ${getBackgroundColor(rank)}`}
                style={{ textShadow: "1px 1px 2px black" }}
              >
                {rank.toUpperCase()}
              </span>
            ))}
            {playerData.banned && (
    <span
      className="text-white px-2 font-bold text-xs rounded-full m-1 bg-red-500"
      style={{ textShadow: "1px 1px 2px black" }}
    >
      BANNED
    </span>
  )}
  {playerData.muted && (
    <span
      className="text-white px-2 font-bold text-xs rounded-full m-1 bg-yellow-500"
      style={{ textShadow: "1px 1px 2px black" }}
    >
      MUTED
    </span>
  )}
        </div>
        {playerData.bio && (
          <p
            className="mb-2 prose prose-invert overflow-x-auto max-w-full"
            dangerouslySetInnerHTML={{ __html: processMarkdownSyntax(playerData.bio) }}
          />
        )}
      </div>
      <hr className="border-gray-700 mb-4" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <p><strong>First Joined:</strong> {new Date(playerData.firstJoined * 1000).toLocaleDateString()}</p>
          <p><strong>Level:</strong> {playerData.level || 0}</p>
          <p><strong>Discord:</strong> {playerData.discordTag || "N/A"}</p>
          <p><strong>Guild:</strong> {playerData.guild || "N/A"}</p>
          <p><strong>Last Seen:</strong> {playerData.lastSeen || "N/A"}</p>
        </div>
        <div>
          <p><strong>Wins:</strong> {playerData.wins || 0}</p>
          <p><strong>Losses:</strong> {playerData.losses || 0}</p>
          <p><strong>Kills:</strong> {playerData.kills || 0}</p>
          <p><strong>Deaths:</strong> {playerData.deaths || 0}</p>
          <p><strong>Credits:</strong> {playerData.credits || 0}</p>
          <p><strong>Playtime:</strong> {formatOnlineTime(playerData.extra.onlineTime)}</p>
        </div>
      </div>
    </div>
  );
};

// GameStats Component
const GameStats = ({ winsData, extra }) => {
  if (!winsData || !extra) return null;

  return (
    <div className="p-6">
      <h4 className="text-xl font-bold mb-4 text-indigo-400">Game Stats</h4>
      <Tabs>
        <TabList className="flex space-x-2 mb-4 overflow-x-auto">
          {Object.keys(winsData).map((game) => (
            <Tab key={game} className="px-4 py-2 bg-gray-700 rounded hover:bg-indigo-600 cursor-pointer">
              {game}
            </Tab>
          ))}
        </TabList>
        {Object.entries(winsData).map(([game, wins]) => (
          <TabPanel key={game}>
            <p><strong>Wins:</strong> {wins}</p>
            {extra[game.toLowerCase()] && (
              <>
                <p><strong>Kills:</strong> {extra[game.toLowerCase()].kills || 0}</p>
                <p><strong>Deaths:</strong> {extra[game.toLowerCase()].deaths || 0}</p>
              </>
            )}
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

// WinStreaks Component
const WinStreaks = ({ winStreaks }) => {
  if (!winStreaks) return null;

  return (
    <div className="p-6">
      <h4 className="text-xl font-bold mb-4 text-indigo-400">Win Streaks</h4>
      <table className="w-full text-left">
        <thead>
          <tr className="border-b border-gray-700">
            <th>Game Mode</th>
            <th>Current</th>
            <th>Best</th>
          </tr>
        </thead>
        <tbody>
          {winStreaks.map((streak) => (
            <tr key={streak.gameKey} className="hover:bg-gray-700">
              <td>{streak.gameKeyFriendly}</td>
              <td>{streak.current}</td>
              <td>{streak.best}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// StatsChart Component
const StatsChart = ({ kills, deaths, wins, losses }) => {
  const data = {
    labels: ["Kills", "Deaths", "Wins", "Losses"],
    datasets: [{
      label: "Player Stats",
      data: [kills || 0, deaths || 0, wins || 0, losses || 0],
      backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
    }],
  };
  const options = {
    plugins: { tooltip: { enabled: true } },
    responsive: true,
  };
  return (
    <div className="p-6">
      <h4 className="text-xl font-bold mb-4 text-indigo-400">Stats Overview</h4>
      <Bar data={data} options={options} />
    </div>
  );
};

function formatOnlineTime(minutes) {
    const weeks = Math.floor(minutes / (60 * 24 * 7));
    const days = Math.floor((minutes % (60 * 24 * 7)) / (60 * 24));
    const hours = Math.floor((minutes % (60 * 24)) / 60);
    const minutesLeft = minutes % 60;
    return `${weeks}W ${days}D ${hours}H ${minutesLeft}M`;
  }

// Punishments Component
const SkeletonLoader = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-900 to-black text-white p-4">
      <div className="container mx-auto">

        {/* Skeleton for PlayerInfo */}
        <div className="bg-gray-800 rounded-lg shadow-lg mt-4 p-6 animate-pulse">
          <div className="flex flex-col items-center mb-6">
            <div className="rounded-full mb-4 border-2 border-indigo-500 w-28 h-28 bg-gray-700"></div>
            <div className="h-8 w-32 bg-gray-700 rounded mb-2"></div>
            <div className="flex flex-wrap justify-center mb-4">
              <div className="h-4 w-16 bg-red-600 rounded-full m-1"></div>
              <div className="h-4 w-20 bg-yellow-600 rounded-full m-1"></div>
              <div className="h-4 w-18 bg-green-600 rounded-full m-1"></div>
            </div>
            <div className="h-4 w-64 bg-gray-700 rounded mb-2"></div>
            <div className="h-4 w-48 bg-gray-700 rounded"></div>
          </div>
          <hr className="border-gray-700 mb-4" />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <div className="h-4 w-24 bg-gray-700 rounded mb-2"></div>
              <div className="h-4 w-32 bg-gray-700 rounded mb-2"></div>
              <div className="h-4 w-28 bg-gray-700 rounded mb-2"></div>
              <div className="h-4 w-20 bg-gray-700 rounded mb-2"></div>
              <div className="h-4 w-24 bg-gray-700 rounded mb-2"></div>
            </div>
            <div>
              <div className="h-4 w-20 bg-gray-700 rounded mb-2"></div>
              <div className="h-4 w-28 bg-gray-700 rounded mb-2"></div>
              <div className="h-4 w-24 bg-gray-700 rounded mb-2"></div>
              <div className="h-4 w-32 bg-gray-700 rounded mb-2"></div>
              <div className="h-4 w-20 bg-gray-700 rounded mb-2"></div>
              <div className="h-4 w-24 bg-gray-700 rounded mb-2"></div>
            </div>
          </div>
        </div>

        {/* Skeleton for GameStats */}
        <div className="bg-gray-800 rounded-lg shadow-lg mt-4 p-6 animate-pulse">
          <div className="h-6 w-32 bg-indigo-400 rounded mb-4"></div>
          <div className="flex space-x-2 mb-4 overflow-x-auto">
            <div className="h-8 w-20 bg-gray-700 rounded"></div>
            <div className="h-8 w-24 bg-gray-700 rounded"></div>
            <div className="h-8 w-16 bg-gray-700 rounded"></div>
          </div>
          <div>
            <div className="h-4 w-24 bg-gray-700 rounded mb-2"></div>
            <div className="h-4 w-28 bg-gray-700 rounded mb-2"></div>
            <div className="h-4 w-20 bg-gray-700 rounded mb-2"></div>
          </div>
        </div>

        {/* Skeleton for WinStreaks */}
        <div className="bg-gray-800 rounded-lg shadow-lg mt-4 p-6 animate-pulse">
          <div className="h-6 w-32 bg-indigo-400 rounded mb-4"></div>
          <table className="w-full text-left">
            <thead>
              <tr className="border-b border-gray-700">
                <th><div className="h-4 w-24 bg-gray-700 rounded"></div></th>
                <th><div className="h-4 w-16 bg-gray-700 rounded"></div></th>
                <th><div className="h-4 w-16 bg-gray-700 rounded"></div></th>
              </tr>
            </thead>
            <tbody>
              {[...Array(3)].map((_, index) => (
                <tr key={index} className="hover:bg-gray-700">
                  <td><div className="h-4 w-28 bg-gray-700 rounded"></div></td>
                  <td><div className="h-4 w-12 bg-gray-700 rounded"></div></td>
                  <td><div className="h-4 w-12 bg-gray-700 rounded"></div></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Skeleton for StatsChart */}
        <div className="bg-gray-800 rounded-lg shadow-lg mt-4 p-6 animate-pulse">
          <div className="h-6 w-32 bg-indigo-400 rounded mb-4"></div>
          <div className="h-64 w-full bg-gray-700 rounded"></div>
        </div>

        {/* Skeleton for Punishments */}
        <div className="bg-gray-800 rounded-lg shadow-lg mt-4 p-6 animate-pulse">
          <div className="h-6 w-32 bg-indigo-400 rounded mb-4"></div>
          <div className="h-8 w-32 bg-indigo-600 rounded mb-4"></div>
          <div className="space-y-2">
            <div className="h-4 w-48 bg-gray-700 rounded"></div>
            <div className="h-4 w-40 bg-gray-700 rounded"></div>
            <div className="h-4 w-52 bg-gray-700 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Main Player Component
// ... (Keep all other imports and components unchanged)

// Updated Punishments Component
const Punishments = ({ punishments, loading, fetched, error, onFetch }) => (
  <div className="p-6">
    <h4 className="text-xl font-bold mb-4 text-indigo-400">Punishments</h4>
    {!fetched && (
      <button
        className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded mb-4"
        onClick={onFetch}
      >
        Punishments
      </button>
    )}
    {loading && (
      <div className="flex items-center justify-center">
        <FaSpinner className="animate-spin mr-2" /> Finding punishments...
      </div>
    )}
    {error && <p className="text-red-500">{error}</p>}
    {fetched && punishments.length === 0 && (
      <p className="text-indigo-500 font-bold">No punishments found for this player.</p>
    )}
    {fetched && punishments.length > 0 && (
      <div className="space-y-4">
        {punishments.map((punishment, index) => (
          <div
            key={index}
            className="bg-gray-700 p-4 rounded-lg transition-all duration-200 hover:bg-gray-600"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              <p><strong>Punishment -</strong> {punishment.type}{punishment.alt && <span className="text-indigo-500 font-bold"> - (Alt Punishment)</span>}</p>
              <p><strong>Reason:</strong> {punishment.reason}</p>
              <p><strong>Category:</strong> {punishment.reasonCategory}</p>
              <p><strong>ID:</strong> {punishment.id}</p>
              <p><strong>Issued At:</strong> {new Date(punishment.issuedAt * 1000).toLocaleString()}</p>
              <p><strong>Valid Until:</strong> {punishment.permanent ? "Permanent" : new Date(punishment.validUntil * 1000).toLocaleString()}</p>
              <p> <strong className={punishment.active ? "text-red-600 font-bold" : "text-green-600 font-bold"}>Active: {punishment.active ? "Yes" : "No"}</strong></p>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
);

// Main Player 
const Player = () => {
  const [playerName, setPlayerName] = useState("");
  const [playerData, setPlayerData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [punishments, setPunishments] = useState([]);
  const [punishmentsLoading, setPunishmentsLoading] = useState(false);
  const [punishmentsFetched, setPunishmentsFetched] = useState(false);
  const [punishmentsError, setPunishmentsError] = useState("");
  const { player } = useParams();
  const navigate = useNavigate();

  const searchPlayer = useCallback(async (name) => {
    setLoading(true);
    setError("");
    setPlayerData(null);
    setPunishments([]);
    setPunishmentsFetched(false);
    try {
      const response = await fetch(
        `https://api.ngmc.co/v1/players/${name}?period=global&withReportCount=true&withUsernameHistory=true&withWinStreaks=true`
      );
      if (!response.ok) throw new Error(`Cannot find ${name}`);
      const data = await response.json();
      if (!data?.name) throw new Error("Invalid data received.");
      setPlayerData(data);
      sendToDiscordWebhook(data.name);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []); // No dependency on playerName
  
  const fetchPunishments = async () => {
    if (!playerData?.xuid) return;
    setPunishmentsLoading(true);
    setPunishmentsError("");
    try {
      const response = await axios.get(`https://api.ngmc.co/v1/players/${playerData.xuid}/punishments`);
      setPunishments(response.data.punishments || []);
      setPunishmentsFetched(true);
    } catch (err) {
      setPunishmentsError("Failed to fetch punishments.");
    } finally {
      setPunishmentsLoading(false);
    }
  };

  const handleNavigate = (name) => {
    if (name) {
      navigate(`/players/${name}`); // Use navigate instead of window.location.href for SPA behavior
      searchPlayer(name); // Explicitly call searchPlayer here
    }
  };

  // Handle URL param changes (initial load or navigation)
  useEffect(() => {
    if (player && player !== playerName) {
      setPlayerName(player);
      searchPlayer(player);
    }
  }, [player, searchPlayer]); // Only runs when player param changes

  const sendToDiscordWebhook = (playerName) => {
    const webhookURL = "https://discord.com/api/webhooks/1353122503236780172/Od93V0NkcJVzCGHpp71lhmXDYGsYG_zD-2VTcaxvjOm9I3t37JuJFODYqt0selPNE_iV";
    fetch(webhookURL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ content: `Player ${playerName} searched successfully!` }),
    }).catch((err) => console.error("Webhook error:", err));
  };

  const processMarkdownSyntax = (text) => {
    return text
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
      .replace(/__(.*?)__/g, "<em>$1</em>")
      .replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2" target="_blank" class="text-indigo-400">$1</a>')
      .replace(/\n/g, "<br/>");
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-900 to-black text-white p-4">
      <ErrorBoundary>
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-6 text-center text-indigo-400">Search Player</h2>
          <PlayerSearch
            playerName={playerName}
            setPlayerName={setPlayerName}
            loading={loading}
            handleNavigate={handleNavigate}
          />
          {loading && <SkeletonLoader />}
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 font-bold px-4 py-3 rounded-md text-center mb-4">
              <p>{error}</p>
            </div>
          )}
          {playerData && (
            <div
              className={`transition-opacity duration-500 ${
                playerData ? "opacity-100" : "opacity-0"
              } bg-gray-800 rounded-lg shadow-lg mt-4 overflow-x-auto`}
            >
              <PlayerInfo playerData={playerData} processMarkdownSyntax={processMarkdownSyntax} />
              {playerData.winsData && playerData.extra && (
                <GameStats winsData={playerData.winsData} extra={playerData.extra} />
              )}
              {playerData.winStreaks && <WinStreaks winStreaks={playerData.winStreaks} />}
              <StatsChart
                kills={playerData.kills}
                deaths={playerData.deaths}
                wins={playerData.wins}
                losses={playerData.losses}
              />
              <Punishments
                punishments={punishments}
                loading={punishmentsLoading}
                fetched={punishmentsFetched}
                error={punishmentsError}
                onFetch={fetchPunishments}
              />
            </div>
          )}
        </div>
      </ErrorBoundary>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
}; 

export default Player;
