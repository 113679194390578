import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaBan, FaVolumeMute, FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";

// SkeletonCard Component with Dark Theme
const SkeletonCard = () => (
  <div className="p-4 border border-gray-800 rounded-lg shadow-sm flex items-start space-x-4 bg-gradient-to-br from-gray-900 to-black animate-pulse">
    <div className="w-8 h-8 bg-gray-700 rounded-full"></div>
    <div className="flex-1 space-y-2">
      <div className="h-4 bg-gray-700 rounded w-1/4"></div>
      <div className="h-4 bg-gray-700 rounded w-3/4"></div>
      <div className="h-4 bg-gray-700 rounded w-1/2"></div>
      <div className="h-4 bg-gray-700 rounded w-1/3"></div>
    </div>
  </div>
);

// PunishmentCard Component with Dark Theme and Gradient
const PunishmentCard = ({ punishment, index }) => (
  <div
    className="p-4 border border-gray-800 rounded-lg shadow-sm flex items-start space-x-4 punishment-card bg-gradient-to-br from-gray-900 to-black"
    style={{ animationDelay: `${index * 0.1}s` }}
  >
    {punishment.type === "BAN" ? (
      <FaBan className="text-red-400 text-2xl flex-shrink-0" />
    ) : punishment.type === "MUTE" ? (
      <FaVolumeMute className="text-yellow-400 text-2xl flex-shrink-0" />
    ) : (
      <FaTimesCircle className="text-gray-400 text-2xl flex-shrink-0" />
    )}
    <div className="flex-1">
      <h2 className="text-xl font-semibold text-gray-200">
        <span
          className={`px-2 py-1 rounded text-white ${
            punishment.type === "BAN"
              ? "bg-gradient-to-r from-red-600 to-red-800"
              : punishment.type === "MUTE"
              ? "bg-gradient-to-r from-yellow-600 to-yellow-800"
              : "bg-gradient-to-r from-gray-600 to-gray-800"
          }`}
        >
          {punishment.type}
        </span>
      </h2>
      <p className="text-gray-300 break-words">
        <strong>Reason:</strong> {punishment.reasonName} ({punishment.reasonCategory})
      </p>
      <p className="text-gray-300">
        <strong>Issued:</strong>{" "}
        {punishment.issuedAt === 0
          ? "Unknown"
          : new Date(punishment.issuedAt * 1000).toLocaleString()}
      </p>
      <p className="text-gray-300">
        <strong>Expires:</strong>{" "}
        {punishment.validUntil
          ? new Date(punishment.validUntil * 1000).toLocaleString()
          : "Permanent"}
      </p>
      <p className="text-gray-300 flex items-center">
  <strong>Active: </strong> {punishment.active ? "Yes" : "No"}
  {punishment.active ? (
    <FaCheckCircle className="ml-2 text-green-400" />
  ) : (
    <FaTimesCircle className="ml-2 text-red-400" />
  )}
</p>
      <p className="text-gray-300">
        <strong>Alt Account:</strong> {punishment.alt ? "Yes" : "No"}
      </p>
      <p className="text-gray-400 text-sm">
        <strong>ID:</strong> {punishment.id}
      </p>
    </div>
  </div>
);

const PunishmentsPage = () => {
  const { player } = useParams();
  const [playerData, setPlayerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchPunishments = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetch(`https://api.ngmc.co/v1/players/${player}/punishments`);
      if (!response.ok) {
        throw new Error("Player not found or API error");
      }
      const data = await response.json();
      setPlayerData(data);
      sendToDiscordWebhook(data.name);
      toast.success(`punishments for ${player}`, {
        style: { background: "#1f2937", color: "#fff" },
      });
    } catch (err) {
      setError(err.message);
      toast.error(`Failed to load punishments: ${err.message}`, {
        style: { background: "#1f2937", color: "#fff" },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPunishments();
  }, [player]);

  const sendToDiscordWebhook = (playerName) => {
    const webhookURL = "https://discord.com/api/webhooks/1353122503236780172/Od93V0NkcJVzCGHpp71lhmXDYGsYG_zD-2VTcaxvjOm9I3t37JuJFODYqt0selPNE_iV";
    fetch(webhookURL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ content: `Found Punishments for ${playerName} successfully!` }),
    }).catch((err) => console.error("Webhook error:", err));
  };

  if (loading) {
    return (
      <div className="container mx-auto p-4 bg-black min-h-screen">
        <Toaster position="top-right" />
        <h1 className="text-3xl font-bold text-white mb-6">Punishments</h1>
        <div className="space-y-4">
          {[...Array(3)].map((_, index) => (
            <SkeletonCard key={index} />
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto p-4 bg-black min-h-screen">
        <Toaster position="top-right" />
        <h1 className="text-3xl font-bold text-white mb-6">Punishments</h1>
        <div className="text-center">
          <p className="text-red-400 mb-4">Error: {error}</p>
          <button
            onClick={fetchPunishments}
            className="px-4 py-2 bg-gradient-to-r from-blue-600 to-blue-800 text-white rounded hover:from-blue-700 hover:to-blue-900 transition-all"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  if (!playerData) return null;

  return (
    <div className="container mx-auto p-4 bg-black min-h-screen">
      <Toaster position="top-right" />
      <h1 className="text-3xl font-bold text-white mb-6">
        Punishments for {playerData.name}
      </h1>
      {playerData.punishments.length === 0 ? (
        <div className="text-center py-6 bg-gradient-to-br from-gray-900 to-black rounded-lg border border-gray-800">
          <p className="text-gray-300 flex items-center justify-center">
            <FaCheckCircle className="mr-2 text-green-400" /> No punishments found for this player.
          </p>
        </div>
      ) : (
        <div className="space-y-4">
          {playerData.punishments.map((punishment, index) => (
            <PunishmentCard key={punishment.id} punishment={punishment} index={index} />
          ))}
        </div>
      )}
    </div>
  );
};

export default PunishmentsPage;
